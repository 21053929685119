import React, { useEffect, useState } from "react";
import { Button, Text, Box, Drawer, DrawerContent, DrawerHeader, DrawerBody, DrawerCloseButton, Flex, Switch } from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import PropTypes from "prop-types";

const CLIENT_ID = process.env.REACT_APP_MICROSOFT_LOGIN; // Microsoft App Client ID
const AUTH_URL = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize";
const TOKEN_URL = "https://login.microsoftonline.com/common/oauth2/v2.0/token";
const REDIRECT_URI = window.location.origin; // Redirect URI set in Azure Portal
const SCOPES = "https://graph.microsoft.com/User.Read";

// Utility functions for PKCE
function generateRandomString(length) {
  const array = new Uint32Array(length);
  window.crypto.getRandomValues(array);
  return Array.from(array, (dec) => ("0" + dec.toString(16)).slice(-2)).join("");
}

async function generateCodeChallenge(verifier) {
  const encoder = new TextEncoder();
  const data = encoder.encode(verifier);
  const digest = await window.crypto.subtle.digest("SHA-256", data);
  return btoa(String.fromCharCode(...new Uint8Array(digest)))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
}

let codeVerifier;

export default function Configurator(props) {
  const [user, setUser] = useState(null); // Store logged-in user info
  const [cookies, setCookie, removeCookie] = useCookies(["creds"]);
  const [switched, setSwitched] = useState(props.isChecked);

  // Handle dark mode toggle
  const colorMode = props.colorMode;
  if (colorMode && colorMode.colorMode === "light") {
    colorMode.toggleColorMode();
  }

  const loginHandler = async () => {
    // Step 1: Generate PKCE code verifier and challenge
    codeVerifier = generateRandomString(128);
    sessionStorage.setItem("code_verifier", codeVerifier); // Persist code_verifier in sessionStorage

    const codeChallenge = await generateCodeChallenge(codeVerifier);

    // Step 2: Redirect to Microsoft's login page
    const authUrl = `${AUTH_URL}?client_id=${CLIENT_ID}&response_type=code&redirect_uri=${encodeURIComponent(
      REDIRECT_URI
    )}&response_mode=query&scope=${encodeURIComponent(SCOPES)}&code_challenge=${codeChallenge}&code_challenge_method=S256`;

    window.location.href = authUrl;
  };

  const fetchToken = async (code) => {
    // Step 3: Retrieve code_verifier from sessionStorage
    const storedCodeVerifier = sessionStorage.getItem("code_verifier");
    if (!storedCodeVerifier) {
      console.error("Code verifier not found in session storage.");
      return;
    }

    try {
      const response = await fetch(TOKEN_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          client_id: CLIENT_ID,
          redirect_uri: REDIRECT_URI, // Ensure it matches the authorization request
          grant_type: "authorization_code",
          code,
          code_verifier: storedCodeVerifier, // Use the same code_verifier
          scope: SCOPES,
        }),
      });

      const data = await response.json();
      if (data.access_token) {
        fetchUserData(data.access_token);
        sessionStorage.removeItem("code_verifier"); // Clear code_verifier after successful exchange
      } else {
        console.error("Token exchange failed:", data);
      }
    } catch (error) {
      console.error("Error fetching token:", error);
    }
  };

  const fetchUserData = async (accessToken) => {
    try {
      const response = await fetch("https://graph.microsoft.com/v1.0/me", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const userData = await response.json();
      setUser(userData);
      setCookie('creds',JSON.stringify({"username":userData.mail,"userid":userData.id}));
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const logoutHandler = () => {
    setUser(null);
    removeCookie("creds");
    window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${REDIRECT_URI}`;
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    if (code) {
      fetchToken(code); // Handle the authorization code returned from login
    }
  }, []);

  return (
    <Drawer
      isOpen={props.isOpen}
      onClose={props.onClose}
      placement={document.documentElement.dir === "rtl" ? "left" : "right"}
      blockScrollOnMount={false}
    >
      <DrawerContent>
        <DrawerHeader pt="24px" px="24px">
          <DrawerCloseButton />
          {user ? (
            <>
              <Text fontSize="xl" fontWeight="bold" mt="16px">
                Καλώς ήρθες
              </Text>
              <Text fontSize="xl" fontWeight="bold" mt="16px">
                {user.displayName || "User"}
              </Text>
              <Button onClick={logoutHandler} my="10px">
                Logout
              </Button>
            </>
          ) : (
            <Button colorScheme="gray" onClick={loginHandler}>
              Σύνδεση
            </Button>
          )}
        </DrawerHeader>
        <DrawerBody w="340px" ps="24px" pe="40px">
          <Flex flexDirection="column">
            <Box>
              <Text fontSize="md" fontWeight="600">
                Sidebar Style
              </Text>
              <Flex>
                <Button
                  w="50%"
                  p="8px 32px"
                  me="8px"
                  colorScheme="teal"
                  variant="outline"
                  fontSize="xs"
                  onClick={props.onTransparent}
                >
                  Transparent
                </Button>
                <Button
                  bg="teal.300"
                  w="50%"
                  p="8px 32px"
                  color="white"
                  fontSize="xs"
                  onClick={props.onOpaque}
                >
                  Opaque
                </Button>
              </Flex>
            </Box>
            <Box display="flex" justifyContent="space-between" mb="16px">
              <Text fontSize="md" fontWeight="600" mb="4px">
                Fixed Navbar
              </Text>
              <Switch
                colorScheme="teal"
                isChecked={switched}
                onChange={() => setSwitched(!switched)}
              />
            </Box>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

Configurator.propTypes = {
  secondary: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onTransparent: PropTypes.func,
  onOpaque: PropTypes.func,
  isChecked: PropTypes.bool,
  colorMode: PropTypes.object,
};
