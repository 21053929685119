// Chakra imports
import {Box,Flex,Grid,Icon,Button,Text,useColorModeValue,AlertDialog,AlertDialogBody,AlertDialogFooter,AlertDialogHeader,AlertDialogContent,AlertDialogOverlay,AlertDialogCloseButton,useDisclosure} from "@chakra-ui/react";
// Assets
import React, {useState,useRef,useEffect} from "react";
import ReactDOM from 'react-dom/client';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import XMLWindow from "./components/XMLWindow";
import UploadApplications from "./components/UploadApplications";
import ComponentPrint from './components/ComponentPrint';
import {useCookies} from 'react-cookie';
import { saveAs } from 'file-saver';
import { pdf, Page, Text as Text1, Font, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import Header from './components/Header';
import DataTable, {createTheme} from 'react-data-table-component';
import memoize from 'memoize-one';
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner.js";
import * as XLSX from "xlsx";
import Policy from 'policy.pdf';
import Manual from 'manual.pdf';

Font.register({family:'OpenSans',fonts:[
  {src:'https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN_r8-VeJoCqeDjg.ttf'},
  {src:'https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN7rg-VeJoCqeDjg.ttf',fontStyle:'bold'},
]});

function Sampling() {

  createTheme('dark',{background:'transparent'});

  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "gray.800"
  );

  const columns = [
    {name: 'Κωδικός Πρότασης',selector: row => row.pske,sortable: true},
    {name: 'Κωδικός Ενέργειας',selector: row => row.code,sortable: true},
    {
      cell: (row) => <button style={{borderColor:bgButton,borderWidth:1,borderRadius:5,padding:5}} onClick={(e) => fetchSampleData(e, row.code, "pdf")}>PDF</button>,
      allowOverflow: true,
      button: true,
    },
    {
      cell: (row) => <button style={{borderColor:bgButton,borderWidth:1,borderRadius:5,padding:5}} onClick={(e) => fetchSampleData(e, row.code, "xls")}>XLS</button>,
      allowOverflow: true,
      button: true,
    }
  ];

  const columnsAvailable = [
    {name: 'Κωδικός Πρότασης',selector:row => row.pske,sortable:true},
    {name: 'Κωδικός Ενέργειας',selector: row => row.code,sortable: true},
    {name: 'Κατάσταση Έργου',selector: row => row.status,sortable: true},
    {
      cell: (row) => <button style={{borderColor:bgButton,borderWidth:1,borderRadius:5,padding:5}} onClick={(e) => prepareSample(e, row.code)}>Εξαγωγή</button>,
      allowOverflow: true,
      button: true,
    }
  ];

  const data = [{id: 1,date: '',pske: '',}];
  const sampleData = [{id: 1,date: '',pske: '',}];
  const dataAvailable = [{pske: 1,code: '',}];
  const textColor = useColorModeValue("gray.700", "white");
  const [history, setHistory] = useState(data);
  const [available, setAvailable] = useState(dataAvailable);
  const [code, setCode] = useState();
  const [show, setShow] = useState();
  const {isOpen,onOpen,onClose} = useDisclosure()
  const cancelRef = React.useRef()
  const [applicationsData, setApplicationsData] = useState();
  const [myerror, setMyError] = useState();
  const [cookies, setCookie, removeCookie] = useCookies(['creds']);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState();

  const styles = StyleSheet.create({
    page: {
      padding: 30,
    },
    container: {
      flex: 1,
      justifyContent:'center', 
      alignItems: 'center',
      flexDirection: 'column',
    },
    image: {
      marginBottom: 10,
    },
    leftColumn: {
      flexDirection: 'column',
      width: 170,
      paddingTop: 30,
      paddingRight: 15,
      '@media max-width: 400': {
        width: '100%',
        paddingRight: 0,
      },
      '@media orientation: landscape': {
        width: 200,
      },
    },
    footer: {
      width:'100%',
      fontSize: 6,
      fontFamily: 'OpenSans',
      textAlign: 'center',
      justifyContent:'center', alignItems: 'center',
      paddingTop: 5,
      borderTopWidth: 0.5,
      borderTopColor: 'gray',
      borderTopStyle: 'dashed',
      '@media orientation: landscape': {
        marginTop: 10,
      },
    },
    tableStyle: { 
      display: "table", 
      width: "auto", 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderRightWidth: 0, 
      borderBottomWidth: 0,
      marginTop:2
    }, 
    tableRow: { 
      margin: "auto", 
      width: "100%", 
      flexDirection: "row" 
    }, 
    tableCol: { 
      width: "100rem",
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0,'whiteSpace': 'unset'
    }, 
    tableCell: { 
      fontFamily: 'OpenSans',
      marginVertical: 4, 
      marginHorizontal:0,
      fontSize: 8,
      justifyContent:'center',
      textOverflow:'false'
    },
    tableHeader: { 
      width: "50rem",
      fontFamily: 'OpenSans',
      fontStyle:'bold',
      margin: "auto", 
      marginVertical: 2, 
      marginHorizontal:2,
      fontSize: 8 ,
      justifyContent:'center'
    }
  });

  if ("creds" in cookies) {
    var url = process.env.REACT_APP_PRODUCTION_URL+"/createSampleM16?username="+cookies.creds.username+"&userid="+cookies.creds.userid+"&code="+code;
    var availableUrl = process.env.REACT_APP_PRODUCTION_URL+"/getAvailablePaymentsM16?username="+cookies.creds.username+"&userid="+cookies.creds.userid;
    var historyUrl = process.env.REACT_APP_PRODUCTION_URL+"/getHistorySampleM16?username="+cookies.creds.username+"&userid="+cookies.creds.userid;
  } else {
    var url = process.env.REACT_APP_PRODUCTION_URL+"/createSampleM16?username=&userid=&code="+code;
    var availableUrl = process.env.REACT_APP_PRODUCTION_URL+"/getAvailablePaymentsM16?username=&userid=";
    var historyUrl = process.env.REACT_APP_PRODUCTION_URL+"/getHistorySampleM16?username=&userid=";
  }
  const generatePdfDocument = async (fileName, pdfDocumentComponent) => {
    const blob = await pdf(pdfDocumentComponent).toBlob();
    saveAs(blob, fileName);
  };
  const ExportXlsx = (a, fileName) => {
    const newData = a.map((item) => {
      const newItem = { ...item };
      Object.keys(item).forEach((key) => {
        if (Array.isArray(item[key])) {
          newItem[key] = item[key].join(", ");
        }
      });
      return newItem;
    });
    const ws = XLSX.utils.json_to_sheet(newData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { fileType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "xlsx" });
    saveAs(data, fileName + ".xlsx");
  };
  const formatDate = (date) => {  
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }
  const calculateTotalAmount = (data) => {
    if (!Array.isArray(data) || data.length === 0) {
      return 0;
    }
    const newDataAmount = data.map((item) => {
      const newItem = { ...item };
      Object.keys(item).forEach((key) => {
        if (Array.isArray(item[key])) {
          newItem[key] = item[key].join(". ");
        }
      });
      return newItem;
    });
    const totalAmount = newDataAmount.reduce((sum, item) => sum + (item["Αιτούμενη αξία"] || 0), 0);
    return totalAmount;
  }
  const MyDocument = ({ selectedSample, total, totalsample }) => {
    // Create an array of column headers based on the selectedSample data
    const columnHeaders = selectedSample.length > 0 ? Object.keys(selectedSample[0]) : [];
    // Function to calculate the width of a text based on its content
    const getTextWidth = (text, fontSize = 8) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      ctx.font = `${fontSize}px OpenSans`; // Use the same font as in styles
      return ctx.measureText(text).width;
    };
    // Calculate column widths based on content and headers
    const columnWidths = columnHeaders.reduce((widths, header) => {
      const headerWidth = getTextWidth(header, 8); // Calculate header width

      const maxWidth = Math.max(
        headerWidth,
        ...selectedSample.map((record) => getTextWidth(String(record[header])))
      );
      widths[header] = Math.min(maxWidth, 200); // Limit max width to 150
      return widths;
    }, {});
    return (
      <Document title="Δείγμα 16" author={cookies.creds.username} creator={cookies.creds.username} producer={cookies.creds.username}>
        <Page size="A4" orientation="landscape" wrap="false">
          <Header username={cookies.creds.username} date={formatDate(new Date())}/>
          <Text1 style={styles.tableCell}>Συνολική Αιτούμενη Αξία: {total.toFixed(2)} €      Αιτούμενη Αξία δείγματος: {totalsample.toFixed(2)} €</Text1>
          <View style={styles.container}>
            <View style={styles.tableStyle}>
              <View style={styles.tableRow}>
                 <View style={styles.tableCol}>
                   <Text1 style={styles.tableHeader}>Κωδικός Πρότασης</Text1>
                 </View>
                 <View style={styles.tableCol}>
                   <Text1 style={styles.tableHeader}>Κωδικός Ενέργειας</Text1>
                 </View>
                 <View style={styles.tableCol}>
                   <Text1 style={styles.tableHeader}>Μέλος</Text1>
                 </View>
                 <View style={styles.tableCol}>
                   <Text1 style={styles.tableHeader}>Παραστατικό</Text1>
                 </View>
                 <View style={styles.tableCol}>
                   <Text1 style={styles.tableHeader}>Υποκατηγορία</Text1>
                 </View>
                 <View style={styles.tableCol}>
                   <Text1 style={styles.tableHeader}>ΑΑ Δαπάνης</Text1>
                 </View>
                 <View style={styles.tableCol}>
                   <Text1 style={styles.tableHeader}>Αιτούμενη αξία</Text1>
                 </View>
                 <View style={styles.tableCol}>
                   <Text1 style={styles.tableHeader}>Επιλέχθηκε στο δείγμα</Text1>
                 </View>
               </View>
              {selectedSample.map((record, i) => (
                 <View style={styles.tableRow} key={record["Παραστατικό Δαπάνης"]}>
                  <View style={styles.tableCol}>
                    <Text1 style={styles.tableCell}>{record["Κωδικός Πρότασης"]}</Text1>
                  </View>
                  <View style={styles.tableCol}>
                    <Text1 style={styles.tableCell}>{record["Κωδικός Ενέργειας"]}</Text1>
                  </View>
                  <View style={styles.tableCol}>
                    <Text1 style={styles.tableCell} wrap>{record["Μέλος"]}</Text1>
                  </View>
                  <View style={styles.tableCol}>
                    <Text1 style={styles.tableCell}>{record["Παραστατικό Δαπάνης"]}</Text1>
                  </View>
                  <View style={styles.tableCol}>
                    <Text1 style={styles.tableCell}>{record["Υποκατηγορία Δαπάνης"]}</Text1>
                  </View>
                  <View style={styles.tableCol}>
                    <Text1 style={styles.tableCell}>{record["ΑΑ Δαπάνης"]}</Text1>
                  </View>
                  <View style={styles.tableCol}>
                    <Text1 style={styles.tableCell}>{record["Αιτούμενη αξία"]}</Text1>
                  </View>
                  <View style={styles.tableCol}>
                    <Text1 style={styles.tableCell}>{record["Επιλέχθηκε στο δείγμα"]}</Text1>
                  </View>
                 </View>
              ))}
            </View>
          </View>
          <Text1 style={styles.tableCell}>Η παρούσα αναφορά αποτυπώνει το δείγμα που εξήχθη για αίτηση πληρωμής του Μέτρου 16 με τυχαία δειγματοληψία.</Text1>
          <Text1 style={styles.footer} render={({ pageNumber, totalPages }) => (
            "ΕΥΔ ΣΣ ΚΑΠ, Μονάδα Χρηματοοικονομικών Ροών και Πληροφοριακών Συστημάτων            "+ `${pageNumber} / ${totalPages}`
          )} fixed />
        </Page>
      </Document>
    );
  };
  // Create MyHistorySample Component
  const MyHistorySample = ({ selectedSample, total, totalsample }) => {
    // Create an array of column headers based on the selectedSample data
    const columnHeaders = selectedSample.length > 0 ? Object.keys(selectedSample[0]) : [];

    // Function to calculate the width of a text based on its content
    const getTextWidth = (text, fontSize = 8) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      ctx.font = `${fontSize}px OpenSans`; // Use the same font as in styles
      return ctx.measureText(text).width;
    };

    // Calculate column widths based on content and headers
    const columnWidths = columnHeaders.reduce((widths, header) => {
      const headerWidth = getTextWidth(header, 8); // Calculate header width

      const maxWidth = Math.max(
        headerWidth,
        ...selectedSample.map((record) => getTextWidth(String(record[header])))
      );
      widths[header] = Math.min(maxWidth, 200); // Limit max width to 150
      return widths;
    }, {});
    return (
      <Document title="Δείγμα 16" author={cookies.creds.username} creator={cookies.creds.username} producer={cookies.creds.username}>
        <Page size="A4" orientation="landscape" wrap="false">
          <Header username={selectedSample[0]['Χρήστης']} date={selectedSample[0]['Ημερομηνία Δειγματοληψίας']}/>
          <Text1 style={styles.tableCell}>Συνολική Αιτούμενη Αξία: {total.toFixed(2)} €      Αιτούμενη Αξία δείγματος: {totalsample.toFixed(2)} €</Text1>
          <View style={styles.container}>
            <View style={styles.tableStyle}>
              <View style={styles.tableRow}>
                 <View style={styles.tableCol}>
                   <Text1 style={styles.tableHeader}>Κωδικός Πρότασης</Text1>
                 </View>
                 <View style={styles.tableCol}>
                   <Text1 style={styles.tableHeader}>Κωδικός Ενέργειας</Text1>
                 </View>
                 <View style={styles.tableCol}>
                   <Text1 style={styles.tableHeader}>Μέλος</Text1>
                 </View>
                 <View style={styles.tableCol}>
                   <Text1 style={styles.tableHeader}>Παραστατικό Δαπάνης</Text1>
                 </View>
                 <View style={styles.tableCol}>
                   <Text1 style={styles.tableHeader}>Υποκατηγορία Δαπάνης</Text1>
                 </View>
                 <View style={styles.tableCol}>
                   <Text1 style={styles.tableHeader}>ΑΑ Δαπάνης</Text1>
                 </View>
                 <View style={styles.tableCol}>
                   <Text1 style={styles.tableHeader}>Αιτούμενη αξία</Text1>
                 </View>
                 <View style={styles.tableCol}>
                   <Text1 style={styles.tableHeader}>Επιλέχθηκε στο δείγμα</Text1>
                 </View>
               </View>
              {selectedSample.map((record, i) => (
                 <View style={styles.tableRow} key={record["Παραστατικό Δαπάνης"]}>
                  <View style={styles.tableCol}>
                    <Text1 style={styles.tableCell}>{record["Κωδικός Πρότασης"]}</Text1>
                  </View>
                  <View style={styles.tableCol}>
                    <Text1 style={styles.tableCell}>{record["Κωδικός Ενέργειας"]}</Text1>
                  </View>
                  <View style={styles.tableCol}>
                    <Text1 style={styles.tableCell}>{record["Μέλος"]}</Text1>
                  </View>
                  <View style={styles.tableCol}>
                    <Text1 style={styles.tableCell}>{record["Παραστατικό Δαπάνης"]}</Text1>
                  </View>
                  <View style={styles.tableCol}>
                    <Text1 style={styles.tableCell}>{record["Υποκατηγορία Δαπάνης"]}</Text1>
                  </View>
                  <View style={styles.tableCol}>
                    <Text1 style={styles.tableCell}>{record["ΑΑ Δαπάνης"]}</Text1>
                  </View>
                  <View style={styles.tableCol}>
                    <Text1 style={styles.tableCell}>{record["Αιτούμενη αξία"]}</Text1>
                  </View>
                  <View style={styles.tableCol}>
                    <Text1 style={styles.tableCell}>{record["Επιλέχθηκε στο δείγμα"]}</Text1>
                  </View>
                 </View>
              ))}
            </View>
          </View>
          <Text1 style={styles.tableCell}>Η παρούσα αναφορά αποτυπώνει το δείγμα που εξήχθη στις {selectedSample[0]["Ημερομηνία Δειγματοληψίας"]} για αιτήσεις πληρωμής του Μέτρου 16.</Text1>
          <Text1 style={styles.footer} render={({ pageNumber, totalPages }) => (
            "ΕΥΔ ΣΣ ΚΑΠ, Μονάδα Χρηματοοικονομικών Ροών και Πληροφοριακών Συστημάτων            "+ `${pageNumber} / ${totalPages}`
          )} fixed />
        </Page>
      </Document>
      )
  };

  const prepareSample = (e, code) => {
    setCode(code);
    onOpen();
  }

  const renameColumns = (data) => {
    data = JSON.stringify(data);
    data = data.replace(/"username":/g, '"Χρήστης":');
    data = data.replace(/"index":/g, '"ΑΑ":');
    data = data.replace(/"code":/g, '"Κωδικός Ενέργειας":');
    data = data.replace(/"subcategory":/g, '"Υποκατηγορία Δαπάνης":');
    data = data.replace(/"pske":/g, '"Κωδικός Πρότασης":');
    data = data.replace(/"amount":/g, '"Αιτούμενη αξία":');
    data = data.replace(/"expenseaa":/g, '"ΑΑ Δαπάνης":');
    data = data.replace(/"date":/g, '"Ημερομηνία Δειγματοληψίας":');
    data = data.replace(/"name":/g, '"Μέλος":');
    data = data.replace(/"invoicenumber":/g, '"Παραστατικό Δαπάνης":');
    data = data.replace(/"selectedsample":/g, '"Επιλέχθηκε στο δείγμα":');
    return JSON.parse(data)
  }
  
  const fetchAvailable = () => {
    setIsLoading(true);
    return fetch(availableUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.myerror) {
          setMyError(data.myerror);
          setIsLoading(false);
          alert(data.myerror);
        }
        else{
          setShow("available");
          setAvailable(data.available);
          setIsLoading(false);
        }
      })
      .catch(error => {console.log("test", error);setIsLoading(false);});
  }

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.myerror) {
        setMyError(data.myerror);
        setIsLoading(false);
        alert(data.myerror);
      } else {
        const renamedData = renameColumns(data.sample);
        fetchAvailable();
        setIsLoading(false);
        generatePdfDocument('sample.pdf', <MyDocument selectedSample={renamedData} total={data.total} totalsample={data.totalsample}/>);
      }
      onClose();
    } catch (error) {console.log("test", error);onClose();setIsLoading(false);}
  }

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const fetchHistoryData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(historyUrl);
      const data = await response.json();
      if (data.myerror) {
        setMyError(data.myerror);
        setIsLoading(false);
        alert(data.myerror);
      } else {
        await setShow("history");
        await setHistory(data.history);
        setIsLoading(false);
      }
    } catch (error) {console.log("test", error);setIsLoading(false);}
  }
  const fetchSampleData = async (e, code, type) => {
    e.preventDefault();
    if ("creds" in cookies) {
      //var getSampleUrl = process.env.REACT_APP_PRODUCTION_URL+"/getSampleM16?username="+cookies.creds.username+"&userid="+cookies.creds.userid+"&code="+code;
      var getSampleUrl = process.env.REACT_APP_PRODUCTION_URL+"/getSampleM16?username="+cookies.creds.username+"&userid="+cookies.creds.userid+"&code="+code;
    } else {
      var getSampleUrl = process.env.REACT_APP_PRODUCTION_URL+"/getSampleM16?username=&userid=&code="+code;
    }
    setIsLoading(true);
    try {
      const response = await fetch(getSampleUrl);
      const data = await response.json();
      if (data.myerror) {
        setMyError(data.myerror);
        alert(data.myerror);
        setIsLoading(false);
      } else {
        const renamedData = renameColumns(data.sample);
        setIsLoading(false);
        if (type === "xls") {
          for (let i = 0; i < renamedData.length; i++) {
            renamedData[i]['Αιτούμενη αξία'] = parseFloat(renamedData[i]['Αιτούμενη αξία']);
          }
          ExportXlsx(renamedData, "Sample");
        } else if (type === "pdf") {
          for (let i = 0; i < renamedData.length; i++) {
            renamedData[i]['Αιτούμενη αξία'] = numberWithCommas(renamedData[i]['Αιτούμενη αξία'].replace('.',','));
          }
          generatePdfDocument("Sample.pdf", <MyHistorySample selectedSample={renamedData} total={data.total} totalsample={data.totalsample}/>);
        }
      }
    } catch (error) {console.log("test", error);setIsLoading(false);}
  }
  
  return (
    <Flex direction='column' pt={{base: "120px", md: "75px"}} >
      <Grid templateColumns={{sm: "1fr", lg: "1fr 1fr"}} templateRows='1fr' spacing='24px'>
        <Card my={{lg:"24px"}} me={{lg:"24px"}} w="150%">
          <Box margin='20px' display= 'flex'>
            <Flex justify='center' mx="2rem" align='center' flexDirection="row" minHeight='60px' w='100%'>
              <Button bg={bgButton} color='white' fontSize='l' variant='no-hover' onClick={fetchAvailable} w='100%' mx="10px" disabled={isLoading}>
                Διαθέσιμα αιτήματα
              </Button>
              <Button bg={bgButton} my="1rem" color='white' fontSize='l' variant='no-hover' onClick={fetchHistoryData} w='100%' mx="10px" disabled={isLoading}>
                Ιστορικό δειγματοληψίας
              </Button>
            </Flex>
          </Box>
          <Text color={textColor} fontSize='s'>Η παρούσα εφαρμογή εξυπηρετεί την ανάγκη εξαγωγής δείγματος επί αιτημάτων πληρωμής του Μέτρου 16. Δείγμα μπορεί να εξαχθεί μόνο για τα διαθέσιμα αιτήματα πληρωμής. Επιπλέον, υπάρχει η δυνατότητα άντλησης προηγούμενων δειγμάτων βάσει ιστορικού εφαρμογής. Η εφαρμογή είναι προσβάσιμη μόνο από πιστοποιημένους χρήστες.</Text>
          <Flex justify='space-between' my="1rem" align='center' flexDirection="row" minHeight='60px' w='100%'>
            <a href={Manual} target = "_blank" style={{color: 'white',margin: '20px 0', fontWeight: 'bold',}}>Οδηγίες χρήσης</a>
            <a href={Policy} target = "_blank" style={{color: 'white',margin: '20px 0', fontWeight: 'bold',}}>Πολιτική Απορρήτου</a>
          </Flex>
          {isLoading && <LoadingSpinner />}
          {show==="available" && !isLoading && (
            <Flex justify='center' my="2rem" align='center' flexDirection="column" minHeight='60px' w='100%'>
              <Text color={textColor} fontSize='s'>Διαθέσιμα για δειγματοληψία</Text>
              <DataTable columns={columnsAvailable} data={available} pagination theme="dark"/>
            </Flex>)
          }
          {show==="history" && !isLoading && (
            <Flex justify='center' my="2rem" align='center' flexDirection="column" minHeight='60px' w='100%'>
              <Text color={textColor} fontSize='s'>Ιστορικό δειγματοληψίας</Text>
              <DataTable columns={columns} data={history} pagination theme="dark"/>
            </Flex>)
          }
        </Card>
      </Grid>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Εξαγωγή δείγματος
            </AlertDialogHeader>
            <AlertDialogBody>
              Είστε σίγουροι ότι θέλετε να προχωρήσετε στην εξαγωγή του δείγματος; Μετά την εκτέλεση, δεν ακυρώνεται το δείγμα.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Άκυρο
              </Button>
              <Button colorScheme='blue' onClick={fetchData} ml={3} disabled={isLoading}>
                Εκτέλεση
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
}

export default Sampling;